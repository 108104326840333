//学生课程考勤
<template>
  <div class="stu_course_check">
    <!-- 时间，筛选 -->
    <div class="ub ub-ac ub-ac">
      <div>时间</div>

      <!-- 日期选择 -->
      <div class="margin_left_40">
        <el-date-picker
          @change="dateChange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="timeValue"
          type="daterange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
        >
        </el-date-picker>
      </div>

      <el-form class="ub ub-ac form-data" :model="form" label-width="120px">
        <el-form-item label="考勤状态">
          <el-select v-model="form.state" class="m-2" placeholder="Select" size="large" clearable @change="selectChange">
            <el-option v-for="item in states" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>

      <!-- 筛选按钮 -->
      <div
        :class="[isArrowDivideClass || form.state !== '' ? 'search_bt_active' : 'search_bt_normal']"
        class="com_bt_action  margin_left_20"
        @click.stop="doSeachAction"
      >
        筛选
      </div>
    </div>

    <!-- 表格部分 -->
    <div class="dv_table_view_content margin_top_20" v-loading="loading">
      <el-table style="width: 99%;margin:auto" border stripe :data="tableData" :max-height="maxHeight">
        <!-- 所在班级 -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="class_date" label="上课日期/星期" width="180"></el-table-column>

        <!-- 上课时段 -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="time_range" label="上课时段" width="180"></el-table-column>

        <!-- 上课教室 -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="room_name" label="上课教室" width="180"></el-table-column>

        <!-- 上课老师 -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="teacher_name" label="上课老师" width="150"></el-table-column>

        <!-- 上课状态 -->
        <el-table-column :show-overflow-tooltip="true" align="center" label="上课状态" width="150">
          <template scope="scope">
            <div>{{ classState(scope.row.class_state) }}</div>
          </template>
        </el-table-column>

        <!-- 考勤状态 -->
        <el-table-column :show-overflow-tooltip="true" align="center" label="考勤状态" width="150">
          <template scope="scope">
            <el-button size="small" :type="userStateColor(scope.row.user_state)">{{ userState(scope.row.user_state) }}</el-button>
          </template>
        </el-table-column>

        <!-- 主题名称 -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="lessonName" label="主题名称" width="180"></el-table-column>

        <!-- 操作 min-width="40%" -->
        <el-table-column align="center" label="操作" min-width="20%">
          <template slot-scope="scope">
            <div class="flex_h h_center">
              <el-button @click="doAction(scope.row)" type="text" size="small" v-if="scope.row.user_state == -1 || scope.row.user_state == -2">补课</el-button>
              <div v-else>-</div>
            </div>
          </template>
        </el-table-column>
        <!-- 考勤状态 -->
        <el-table-column :show-overflow-tooltip="true" align="center" label="备注" width="150">
          <template scope="scope">
            <div v-if="scope.row.repair_state === '-1'">
              -
            </div>
            <div v-else>
              已完成补课
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">{{ scope.row.repair_date + " " + scope.row.repair_time_range }}</div>
                <i class="el-icon-info"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <makeup-lessons
      :isMakeupLessonsDialog="isMakeupLessonsDialog"
      :item="makeupLessonsData"
      :actUserInfo="actUserInfo"
      @closeMakeupLessonDialog="closeMakeupLessonDialog"
    ></makeup-lessons>
  </div>
</template>
<script>
import MakeupLessons from "./components/MakeupLessons.vue";
export default {
  data() {
    let self = this;
    return {
      states: [
        {
          name: "所有",
          id: ""
        },
        {
          name: "待出勤",
          id: -3
        },
        {
          name: "未到",
          id: -2
        },
        {
          name: "请假",
          id: -1
        },
        {
          name: "正常",
          id: 1
        },
        {
          name: "迟到",
          id: 0
        }
      ],
      form: {
        state: ""
      },
      //筛选按钮是否允许点击的标识
      isArrowDivideClass: false,

      //日期选择的配置项
      pickerOptions: {
        //设置禁用日期,2022年1月1日之前的不允许选择,最多选择到当前日期
        disabledDate(time) {
          let todayDate = self.getLastYearYestdy();
          let dates = new Date(todayDate);
          return time.getTime() <= dates.getTime() || time.getTime() >= new Date().getTime();
        }
      },

      //日期选择器的值
      timeValue: [],

      //表格最大高度
      maxHeight: 0,
      tableData: [],
      loading: false,
      isMakeupLessonsDialog: false,
      makeupLessonsData: null
    };
  },
  created() {
    this.init();
    this.userInfo = this.getUserInfo();
    let actUserInfo = this.$route.query.userInfo;
    if (actUserInfo) {
      this.actUserInfo = JSON.parse(actUserInfo);
      console.log(this.actUserInfo);
    }
    // console.log(this.getLastYearYestdy());

    this.getData();
  },

  computed: {
    /**
     * 上课状态
     */
    classState() {
      return function(state) {
        switch (state) {
          case -1:
            return "已取消";
          case 0:
            return "未上";
          case 1:
            return "已上";

          default:
            return "-";
        }
      };
    },

    /**
     * 考勤状态
     */
    userState() {
      return function(state) {
        switch (Number(state)) {
          case -3:
            return "待出勤";
          case -2:
            return "未到";
          case -1:
            return "请假";
          case 0:
            return "迟到";
          case 1:
            return "正常";

          default:
            return "-";
        }
      };
    },

    /**
     * 考勤状态颜色
     */
    userStateColor() {
      return function(state) {
        switch (Number(state)) {
          case -3:
            return "warning";
          case -2:
          case -1:
            return "danger";
          case 0:
          case 1:
            return "success";

          default:
            return "-";
        }
      };
    }
  },

  methods: {
    getLastYearYestdy() {
      let date = new Date();
      var strYear = date.getFullYear() - 1;
      var strDay = date.getDate();
      var strMonth = date.getMonth() + 1;
      if (strMonth < 10) {
        strMonth = "0" + strMonth;
      }
      if (strDay < 10) {
        strDay = "0" + strDay;
      }
      let datastr = strYear + "-" + strMonth + "-" + strDay;
      return datastr;
    },

    //初始化表格的最大高度
    init() {
      this.maxHeight = document.body.clientHeight - 77 - 56 - 100;
    },
    //筛选的操作
    doSeachAction() {
      if (!this.isEmpty(this.timeValue) || this.form.state !== "") {
        //获取开始时间和结束时间
        this.getData();
      }
    },

    //表格条目的操作
    doAction(item) {
      this.isMakeupLessonsDialog = true;
      this.makeupLessonsData = item;
    },

    /**
     * 关闭补课弹窗
     * @param {Boolean} isRefresh 是否刷新数据
     */
    closeMakeupLessonDialog(isRefresh) {
      this.isMakeupLessonsDialog = false;
      if (isRefresh) {
        this.getData();
      }
    },

    /**
     * 获取数据
     */
    getData() {
      console.log(this.timeValue);
      console.log(this.actUserInfo);
      if (this.actUserInfo.student_subjects.length == 0) {
        return;
      }
      // this.actUserInfo.student_subjects = [{class_id: 1}, {class_id: 2}]
      let class_ids = this.actUserInfo.student_subjects.map(function(item) {
        return item.class_id;
      });
      console.log(class_ids.join(","));
      this.loading = true;
      this.$http
        .fetchPost(this.$api.StudentManageController, {
          type: 15,
          student_id: this.actUserInfo.user_id,
          class_ids: class_ids.join(","),
          begin_date: this.timeValue.length == 0 ? "" : this.timeValue[0],
          end_date: this.timeValue.length == 0 ? "" : this.timeValue[1],
          uid: this.userInfo.id,
          token: this.userInfo.token,
          user_state: this.form.state
        })
        .then(res => {
          this.loading = false;
          if (res.data.state == 200) {
            this.tableData = res.data.data.data_list;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch(err => {
          this.loading = false;
        });
    },

    /**
     * 清除时间筛选
     */
    dateChange(e) {
      if (!this.isDefine(e)) {
        this.timeValue = [];
        this.getData();
      }
    },

    /**
     * 清除select
     */
    selectChange() {
      if (this.form.state === "") {
        this.getData();
      }
    }
  },

  watch: {
    timeValue(newVal, oldVal) {
      if (!this.isEmpty(newVal)) {
        this.isArrowDivideClass = true;
      } else {
        this.isArrowDivideClass = false;
      }
    },

    $route(newVal) {
      console.log(newVal);
      if (newVal.name == "StudentCourseCheck") {
        this.getData();
      }
    }
  },
  components: { MakeupLessons }
};
</script>
<style lang="scss" scoped>
.stu_course_check {
  height: calc(100vh - 77px - 56px);
  display: flex;
  flex-direction: column;

  ::v-deep .el-input__inner {
    height: 36px;
    line-height: 36px;
    width: 260px;
  }

  ::v-deep .el-range-separator {
    line-height: 30px;
    margin-right: 6px;
    margin-left: 2px;
  }

  ::v-deep .el-range__icon {
    line-height: 30px;
  }

  .form-data {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
