<template>
  <de-dialog 
        :isDialog="isMakeupLessonsDialog"
        title="补课"
        class="makeup-lessons-dialog"
        @cancelDialog="cancelDialog"
        @confirmDialog="confirmDialog()"
        confirmText="保存">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="选择日期">
                    <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.date" style="width: 100%;" @change="inputChange"></el-date-picker>
                </el-form-item>
                <el-form-item class="time-form-item" label="选择时间">
                    <el-select v-model="form.time" placeholder="请选择">
                        <el-option
                        v-for="item in timeOptions"
                        :key="item.time_id"
                        :label="item.time_range"
                        :value="item.time_id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            
  </de-dialog>
</template>

<script>
import DeDialog from '../../../../components/DeDialog.vue'
export default {
    components: { DeDialog },
    props: {
        isMakeupLessonsDialog: {
            type: Boolean,
            default: false
        },

        actUserInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },

        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            timeOptions: [],
            form: {
                date: '',
                time: ''
            }
        }
    },

    methods: {
        cancelDialog() {
            this.$emit('closeMakeupLessonDialog', false)
        },

        /**
         * 获取可选补课时间段
         */
        getData() {
            this.$http
                .fetchPost(this.$api.CLASSMANAGER, {
                    type: 20,
                    aid: this.actUserInfo.user_id,
                    cid: this.actUserInfo.student_subjects[0].class_id,
                    sid: this.actUserInfo.school_id,
                    uid: this.userInfo.id,
                    token: this.userInfo.token
                })
                .then((res) => {
                    console.log(res)
                    if (res.data.state == 200) {
                        this.timeOptions = res.data.data
                    } else {
                        this.showErrorMsg(this.getMesage(res.data.state));
                    }
                }).catch((err) => {
                    
                })
        },

        confirmDialog() {
            if (!this.isDefine(this.form.date)) {
                this.$message({
                    message: '请选择日期',
                    type: 'warning'
                })
                return
            }

            if (!this.isDefine(this.form.time)) {
                this.$message({
                    message: '请选择开始时间',
                    type: 'warning'
                })
                return
            }

            this.submitMakeupLessonData()
        },

        /**
         * 提交补课数据
         */
        submitMakeupLessonData() {
            console.log({
                    type: 16,
                    stuId: this.actUserInfo.user_id,
                    planId: this.item.plan_id,
                    classDate: this.form.date,
                    timeId: this.form.time,
                    uid: this.userInfo.id,
                    token: this.userInfo.token
                })
            this.$http
                .fetchPost(this.$api.StudentManageController, {
                    type: 16,
                    stuId: this.actUserInfo.user_id,
                    planId: this.item.plan_id,
                    classDate: this.form.date,
                    timeId: this.form.time,
                    uid: this.userInfo.id,
                    token: this.userInfo.token
                })
                .then((res) => {
                    console.log(res)
                    if (res.data.state == 200) {
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        })
                        setTimeout(() => {
                            this.$emit('closeMakeupLessonDialog', true)
                        }, 1500)
                    } else {
                        this.showErrorMsg(this.getMesage(res.data.state));
                    }
                }).catch((err) => {
                    
                })
        },

        /**
         * 选择结束时间
         */
        endTimeChange(e) {
            console.log(e)
            if (!this.isDefine(this.form.startTime)) {
                this.$message({
                    message: '请先选择开始时间',
                    type: 'warning'
                })
                this.form.endTime = ''
                return
            }
        },

        inputChange(e) {
        }
    },

    watch: {
        isMakeupLessonsDialog(newVal) {
            if (newVal) {
                console.log(this.item)
                console.log(this.actUserInfo)
                this.userInfo = this.getUserInfo();
                this.getData()
            }
        }
    }
}
</script>

<style lang="scss">
.makeup-lessons-dialog {
    .time-form-item {
        .el-form-item__content {
            .el-date-editor {
                width: 210px;
                .el-input__inner {
                    width: 210px;
                }
            }
        }
    }
}
</style>